var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "transfer-service-section" }, [
    _vm.domainUnavailable
      ? _c(
          "div",
          { staticClass: "unavailable-prompt-section" },
          [
            _c("span", [
              _vm._v(" " + _vm._s(_vm.text.domain.unavailable) + " "),
            ]),
            _c(
              "b-link",
              {
                staticClass: "transfer-link",
                attrs: { "aria-label": "Transfer link" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("show-transfer-modal")
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.text.domain.transfer) + "\n    ")]
            ),
          ],
          1
        )
      : _c("div", { staticClass: "initial-prompt-section" }, [
          _vm._m(0),
          _c("div", [
            _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c(
                  "b-link",
                  {
                    staticClass: "transfer-link",
                    attrs: { "aria-label": "Transfer link" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("show-transfer-modal")
                      },
                    },
                  },
                  [
                    _c("arrow-repeat"),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.transferPrompt()) +
                        "\n        "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "divider-container" }, [
      _c("hr", { staticClass: "divider" }),
      _c("span", [_vm._v("Or")]),
      _c("hr", { staticClass: "divider" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }