<template>
  <div class="transfer-service-section">
    <div v-if="domainUnavailable" class="unavailable-prompt-section">
      <span> {{ text.domain.unavailable }} </span>
      <b-link
        class="transfer-link"
        aria-label="Transfer link"
        @click="$emit('show-transfer-modal')"
      >
        {{ text.domain.transfer }}
      </b-link>
    </div>
    <div v-else class="initial-prompt-section">
      <div class="divider-container">
        <hr class="divider">
        <span>Or</span>
        <hr class="divider">
      </div>
      <div>
        <div class="mt-3">
          <b-link
            class="transfer-link"
            aria-label="Transfer link"
            @click="$emit('show-transfer-modal')"
          >
            <arrow-repeat />
            {{ transferPrompt() }}
          </b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransferServiceSection',
  components: {
    ArrowRepeat: () => import('@images/ui/arrow-repeat.svg'),
  },
  props: {
    serviceType: {
      type: String,
      default: '',
    },
    domainUnavailable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: {
        hosting: "Transfer My Website",
        domain: {
          initialPrompt: "I'm transferring in existing domain(s).",
          unavailable: "Do you own this domain?",
          transfer: "Transfer it now.",
        },
      },
    }
  },
  computed: {},
  methods: {
    transferPrompt() {
      switch(this.serviceType) {
        case 'hosting':
          return this.text.hosting
        case 'domain':
          return this.text.domain.initialPrompt
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// Palette
$light-grey: #918F8F;

.transfer-service-section {

  .transfer-link {
    color: $ct-ui-color-10;
    text-decoration: underline;
  }
  .initial-prompt-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .divider-container {
      display: grid;
      grid-template-columns: 2fr auto 2fr;
      gap: 0.63em;
      align-items: center;
      justify-content: center;
      font-weight: $ct-ui-font-weight-7;
      width: 100%;
      max-width: 22em;
      margin-top: 2.5em;
      margin-bottom: 1em;

      hr.divider {
        width: 100%;
        border-color: $light-grey;
      }
    }
  }
}
</style>
